.mt-input {
  height: 54px;
  display: flex;
  align-items: flex-end;
  // border-bottom: 1px solid var(--metalic-silver);
  // position: relative;

  // &__label {
  //   color: var(--secondary-color);
  //   position: absolute;
  //   bottom: 6px;
  //   left: 0;
  //   transition: all 0.25s ease-in-out;
  //   pointer-events: none;
  // }

  // input {
  //   outline: none;
  //   padding: 6px 0;

  //   &:focus {
  //     + .mt-input__label {
  //       bottom: 28px;
  //     }
  //   }
  // }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.otp-input {
  display: block;
  width: 40px !important;
  height: 40px;
  border-radius: 10px;
  border: 2px solid var(--metalic-silver);
  input {
    color: var(--seconardy-color);
  }
}

.mt-text-area {
  min-height: 54px;
  display: flex;
  align-items: flex-end;
  textarea {
    resize: none;
    overflow: hidden;
    @include text-body(xl);

    margin-top: 10px;
  }
  label {
    @include text-body(md);
  }
  .peer:placeholder-shown ~ .peer-placeholder-shown {
    @include text-body(xl);
  }
  .peer:focus ~ .peer-focus {
    @include text-body(md);
  }
}

.mt-input {
  input {
    @include text-body(xl);
  }
  label {
    @include text-body(md);
  }
  .peer:placeholder-shown ~ .peer-placeholder-shown {
    @include text-body(xl);
  }
  .peer:focus ~ .peer-focus {
    @include text-body(md);
  }
}

.mt-autocomplete {
  padding-bottom: 18px;
  &__label {
    @include text-title(lg);
    padding-bottom: 16px;
  }

  // input.ant-input {
  border-width: 1px;
  border-radius: 16px;
  overflow: hidden;
  border-color: var(--metalic-silver);
  border-style: solid;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0 4px;
  height: 56px;
  background: transparent;

  text-align: left;
  padding-left: 16px;
  padding-right: 10px;

  @include text-body(lg);
  &-disabled {
    background-color: #0000000a;
  }
  // }
  &:hover,
  &:focus,
  &:active {
    border-color: var(--metalic-silver) !important;
    box-shadow: none;
    border-width: 1px;
    border-style: solid;
  }

  &input::-webkit-input-placeholder {
    color: var(--secondary-color) !important;
  }

  input[readonly] {
    opacity: 0.8;
  }
  .ant-input-suffix {
    font-size: 12px;
  }
}
