// Poster Back Right Entrance Animation

.agency-page {
  display: flex;
  min-height: 100vh;
  padding-top: var(--header-height);

  &__content {
    display: flex;
    flex-direction: row;
    padding: 40px 0;
    justify-content: space-around;
    align-items: center;
    flex: 1 1;
  }

  &__overview {
    flex: 1;
    .title {
      padding-top: 48px;
    }
  }
  &__form {
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;

    .mt-form {
      max-width: 460px;
      width: 100%;
      padding: 24px 40px;
    }

    .subtitle {
      @include text-body(lg);
      color: var(--black-60);
      padding-top: 12px;
      padding-bottom: 24px;
    }
  }

  @include media-breakpoint-down(md) {
    &__content {
      flex-direction: column;
      row-gap: 56px;
      padding-bottom: 36px;
    }
  }
}

@include media-breakpoint-down(sm) {
}

@include media-breakpoint-down(xs) {
}

.mt-form {
  container-type: inline-size;
  container-name: form-container;
  padding: 30px;
  border-radius: 24px;
  background: var(--white-100, #fcfbfc);
  transition: all 0.3s ease;
  &:hover {
    @include shadow(md);
  }
}
