.mt-media {
  &__video {
    padding-top: 56.25%;
    position: relative;

    video {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 8px;
      z-index: 1;
      object-fit: cover;
    }

    .video-icon {
      width: 38px;
      height: 38px;
      border-radius: 38px;
      background-color: rgba(17, 19, 24, 0.48);
      position: absolute;
      left: 20px;
      bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      z-index: 10;

      svg path {
        fill: var(--anti-flash);
      }
    }
  }

  &__video-thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    border-radius: 8px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;
      background-color: var(--anti-flash);
      border-radius: 8px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      position: relative;
      z-index: 1;
    }
  }
}

.mt-carousel {
  height: 100%;
  .ant-carousel,
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    height: 100%;
  }

  &__arrow {
    // color: black;
    font-size: var(--font-lg);
    line-height: 1.5;
    z-index: 100;
    top: 20px;

    &.slick-next,
    &.slick-prev {
      width: fit-content;
      height: fit-content;
      opacity: 0.25;
      &:hover {
        opacity: 0.6;
      }
    }
    &.slick-next {
      inset-inline-end: 6px;
    }
    &.slick-prev {
      inset-inline-start: 6px;
    }
    @include media-breakpoint-down(sm) {
      &.slick-next {
        inset-inline-end: 16px;
      }
      &.slick-prev {
        inset-inline-start: 16px;
      }
    }
  }

  &.mt-carousel-img {
    .ant-carousel {
      height: 100%;
      .slick-slide {
        div {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  &.mt-carousel-el {
    .slick-track {
      height: 100%;
      display: flex;
    }
  }
  .ant-carousel {
    height: 100%;
    .slick-dots li {
      margin-inline: 8px;
    }

    .slick-dots-bottom {
      bottom: 54px;

      @include media-breakpoint-down(lg) {
        bottom: 24px;
      }
    }
  }
  &__dots {
    height: 16px;
    li,
    button {
      height: 12px !important;
      width: 12px !important;
      border-radius: 12px !important;
      background-color: #11131840;
      @include shadow(sm);
    }
    li.slick-active {
      button {
        background-color: var(--secondary-color) !important;
      }
    }
  }
}
