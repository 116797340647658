.faq {
  background-color: #fff;

  display: flex;
  flex-direction: column;

  [data-custom-class="body"] * {
    font-family: var(--font-family) !important;
  }

  .faq-link {
    text-decoration: underline;
    color: var(--primary-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  .faq-text-bold {
    font-weight: 500;
    color: var(--primary-color);
  }

  .header {
    position: static;
  }

  &-header {
    justify-content: center;
    display: flex;
    &-container {
      max-width: 526px;
    }
    padding-top: 16px;
    padding-bottom: 32px;
  }

  &__filter {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-header {
      max-width: 510px;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    &-title {
      @include text-display(md);
      color: var(--font-color);
      text-align: center;
    }

    &-label {
      @include text-heading(lg);
      color: var(--font-color);
      text-align: center;
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &-input {
      border-width: 1px !important;
      border-style: solid !important;
      border-color: var(--metalic-silver) !important;
      border-radius: 52px;
      @include text-body(md);
      padding: 12px;
      &:hover {
        border-inline-end-width: 0;
      }
      .ant-input-prefix {
        margin-inline-end: 8px;
        color: #11131840;
      }
    }
    &-sections {
      display: flex;
      gap: 28px;
      flex-wrap: wrap;
      justify-content: center;
    }

    &-card {
      padding: 32px 24px;
      flex-direction: column;
      align-items: center;
      display: flex;
      gap: 10px;
      max-width: 266px;
      background-color: var(--anti-flash);
      border-radius: 16px;
      text-align: center;
      cursor: pointer;
      &:hover {
        transition-duration: 0.8s;
        @include shadow(md);
      }

      &-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
      }
      &-content {
        color: #555e77;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    &-icon {
      height: 72px;
      width: 72px;
      border-radius: 100px;
      background-color: #fff;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__section {
    &-title {
      color: var(--font-color);
      font-size: 26px;
      font-weight: 700;
      text-decoration: underline;

      padding-top: 0px;
      margin-bottom: 28px;
    }

    &-question {
      color: var(--font-color);
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
    }
    &-item {
      @include text-body(lg);
      padding-bottom: 12px;
    }
    &-image {
      display: flex;
      column-gap: 8px;
      margin-top: 4px;
    }
    &-content {
      row-gap: 14px;
      display: flex;
      flex-direction: column;
    }
    &-subcontent {
      display: flex;
      flex-direction: column;

      &-title {
        color: var(--font-color);
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }
      &-option {
        margin-block-start: 4px;
      }
    }

    .ant-collapse-expand-icon > svg {
      transition: all 0.3s ease-in;
    }
    &-rotate {
      transform: rotate(180deg);
    }
  }

  &__email {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 44px 0;
    align-items: center;
    justify-content: center;
    gap: 24px;

    &-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
    &-button {
      padding: 12px 16px;
      min-width: 182px;
      height: auto;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.16px;
      text-align: center;
    }
  }

  &__content {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &-container {
      margin-bottom: 150px;
      @include media-breakpoint-down(md) {
        margin-bottom: 32px;
        margin-right: 0px;
      }
      flex: 1;
    }
  }
  .ant-select .ant-select-clear {
    right: 16px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .ant-collapse-header {
    align-items: center;
  }
}
.faq ul.faq__section-subcontent-option {
  margin-block-start: 4px;
}

.faq.pre-order .faq__section {
  margin: 0 -16px;
}

.faq-detail.policy {
  .faq-detail-container {
    .wrapper {
      @include grid(10);

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }
}

.faq-detail {
  padding-top: 20px;
  padding-bottom: 36px;

  // &.container {
  //   max-width: 1440px;
  // }

  &-container {
    display: flex;
    justify-content: center;
    .wrapper {
      @include grid(7);

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }

  &-header {
    justify-content: center;
    display: flex;
    &-container {
      max-width: 526px;
    }
    padding-top: 24px;
    padding-bottom: 32px;
  }
  &-title {
    color: var(--font-color);
    @include text-display(md);
    text-align: center;
    padding-bottom: 8px;
  }
  &-subtitle {
    @include text-body(xxl);
    color: var(--font-color-secondary);
    text-align: center;
  }
  &.policy {
    .faq-detail-subtitle {
      @include text-body(xl);
      color: var(--font-color-secondary);
      text-align: center;
    }
    .mt-table-content {
      column-count: 1;
      @include media-breakpoint-up(lg) {
        column-count: 2;
      }
      @include media-breakpoint-up(xl) {
        column-count: 3;
      }
    }
  }
}
