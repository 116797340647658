.checkout-page {
  display: flex;
  justify-content: space-between;
  &__info {
    max-width: 350px;
  }
  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: flex-start;
    &__info {
      max-width: 100%;
    }
  }
}
