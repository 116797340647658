.mt-input-number {
  padding-bottom: 18px;
  &__label {
    @include text-title(xl);
    padding-bottom: 16px;
  }

  .mt-input-number__container {
    .ant-input-number-group {
      border-width: 1px;
      border-radius: 16px;
      overflow: hidden;
      border-color: var(--metalic-silver);

      .ant-input-number {
        &-disabled {
          background-color: #0000000a;
        }
        input {
          border: none;
          display: flex;
          text-align: center;
          justify-content: center;
          padding: 0 4px;
          height: 54px;

          @include text-button(lg);
        }
      }
      .ant-input-number-group-addon {
        overflow: hidden;
        padding: 0;
        // background-color: white;
        background-color: transparent;
        border: none;
        cursor: pointer;
        .action {
          margin-top: -3px;
          padding: 16px;
        }
        .disabled {
          color: #00000040;
          background-color: #0000000a;
        }
      }
    }

    .ant-select-arrow,
    .ant-select-selection-placeholder {
      color: var(--secondary-color);
    }
  }
  &.sm {
    .mt-input-number__container {
      .ant-input-number-group {
        .ant-input-number {
          input {
            padding: 0 4px;
            height: 34px;

            @include text-button(md);
          }
        }
      }
      .ant-input-number-group-addon {
        .action {
          margin-top: 1px;
          padding: 4px 8px;
        }
      }
    }
  }
}

.mt-input-outline {
  padding-bottom: 18px;
  &__label {
    @include text-title(xl);
    padding-bottom: 16px;
  }

  input {
    border-width: 1px;
    border-radius: 16px;
    overflow: hidden;
    border-color: var(--metalic-silver);
    border-style: solid;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0 4px;
    height: 56px;
    background: transparent;

    text-align: left;
    padding: 0 16px;

    @include text-button(lg);
    &-disabled {
      background-color: #0000000a;
    }
  }

  .ant-input:hover,
  .ant-input:focus {
    border-color: var(--metalic-silver);
    box-shadow: none;
  }

  input::-webkit-input-placeholder {
    color: var(--secondary-color) !important;
  }

  input[readonly] {
    opacity: 0.8;
  }
}
