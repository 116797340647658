$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

$shadows: (
  xs: 0px 1px 2px 0px rgba(0, 0, 0, 0.25),
  sm: (
    0px 1px 1px 0px rgba(0, 0, 0, 0.05),
    0px 0px 1px 0px rgba(0, 0, 0, 0.15),
  ),
  md: (
    0px 4px 8px 0px rgba(0, 0, 0, 0.1),
    0px 0px 4px 0px rgba(0, 0, 0, 0.1),
  ),
  lg: (
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
  ),
  xl: (
    0px 26px 80px 0px rgba(0, 0, 0, 0.2),
    0px 0px 1px 0px rgba(0, 0, 0, 0.15),
  ),
  xxl: (
    0px -26px 80px 0px rgba(0, 0, 0, 0.2),
    0px -1px 2px 0px rgba(0, 0, 0, 0.05),
  ),
) !default;

$text-heading: (
  xxs: 16,
  xs: 20,
  sm: 24,
  md: 28,
  lg: 32,
  xl: 36,
  xxl: 40,
);

$text-heading-line-height: (
  xxs: 20,
  xs: 26,
  sm: 32,
  md: 36,
  lg: 40,
  xl: 44,
  xxl: 52,
);

$text-title: (
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 20,
  xxl: 24,
);

$text-title-line-height: (
  xs: 14,
  sm: 16,
  md: 20,
  lg: 22,
  xl: 28,
  xxl: 32,
);

$text-body: (
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 20,
  xxl: 24,
  xxxl: 32,
);

$text-body-line-height: (
  xs: 14,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 28,
  xxl: 36,
  xxxl: 44,
);

$text-display: (
  sm: 32,
  md: 40,
  lg: 48,
  xl: 64,
);

$text-display-line-height: (
  sm: 38,
  md: 48,
  lg: 58,
  xl: 80,
);

$text-button-line-height: (
  xs: 14,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 26,
);

$text-button: (
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 20,
);

$letter-spacing-button: (
  xs: -0.1px,
  sm: -0.12px,
  md: -0.14px,
  lg: -0.16px,
  xl: -0.2px,
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

$primary-color: #000;
$secondary-color: #111318;
$metalic-silver: #a5a8ad;
$platium: #e6e6e6;
$anti-flash: #f2f2f3;

$header-height: 82px;

:root {
  --primary-color: #000;
  --secondary-color: #111318;
  --secondary-color-rgb: 17, 19, 24;
  --metalic-silver: #a5a8ad;
  --platium: #e6e6e6;
  --platium-rgb: 230, 230, 230;
  --anti-flash: #f2f2f3;

  --footer-bg-color: #e6e6e6;
  --border-color: #111318;

  --font-color: #111318;
  --font-color-secondary: #11131873;

  --danger-color: #df3030;

  --header-height: 82px;

  --font-family: "Roboto", sans-serif;

  --white-50: #f0f0f0;
  --white-200: #f0f0f0;
  --white-500: #f6f6f6;
  --white-1000: #fcfbfc;

  --silver-50: #f7f7f8;
  --silver-100: #f2f2f3;
  --silver-200: #eaeaec;
  --silver-300: #dfe0e2;

  --black-50: #e4e6ec;
  --black-60: #11131899;
  --black-100: #d5d9e1;
  --black-200: #bac0ce;
  --black-300: #9fa7bb;
  --black-400: #858fa8;
  --black-500: #687492;
  --black-600: #555e77;
  --black-700: #42495d;
  --black-800: #1f2937;
  --black-900: #1b1f27;
  --black-1000: #111318;

  --main-border-color: #d7d8db;

  --tertiary: #11131840;

  --support-success: #196c42;

  --deep-dive-width: 100vw;
  --deep-dive-padding: 0;
  --deep-dive-transition-duration: 1000ms;
  --deep-dive-actual-width: 100vw;
  --deep-dive-overlap: 10vw;
  --deep-dive-progress: 0.005;
  --deep-dive-progress-min: 0.005;

  --font-sm: 12px;
  --font-md: 16px;
  --font-lg: 26px;
  --font-xl: 42px;
}

// [data-theme="dark"] {
//   --primary-color: #9a97f3;
//   --secondary-color: #818cab;
//   --font-color: #e1e1ff;
//   --bg-color: #161625;
//   --heading-color: #818cab;
// }
